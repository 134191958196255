import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {gsap} from 'gsap'
import {Link} from 'react-router-dom'
import LazyImageComponent from './../../LazyImageComponent'
import {Animate} from 'react-move'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LinkWrapper from './../../LinkWrapper'
import {MathUtils} from './../../../helpers'

function ArchivePeopleThumbImage({images, link, inFocus, parallaxAmount}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const heightRatio = images[0].height/images[0].width;
  const [activeImg, setActiveImg] = useState(0)

  const [inFocusTimer, setInFocusTimer] = useState();
  
  const styles = {
    paddingBottom: heightRatio * 100+'%',
  }

  const changeImage = () => {
    setActiveImg(activeImg => activeImg == images.length-1 ? 0 : activeImg + 1)
  }

  useEffect(() => {
    if (inFocus) {
      changeImage();
      let t = setInterval(changeImage, 700)
      setInFocusTimer(t);
    } else {
      clearInterval(inFocusTimer);
    }
  }, [inFocus])
  
  return (
    <div className={`ArchivePeopleThumbImage ${isLoaded ? 'loaded' : 'loading'} ${inFocus ? 'infocus' : ''}`} style={styles}>
        <div className="img-wrapper parallax-img" data-parallaxamount={parallaxAmount}>
        {images.map((ele, i) => (
            <img className={`${activeImg == i ? 'active' : 'hide'}`} key={i} src={ele.sizes.medium_large} onLoad={() => {if (i == 0) setIsLoaded(true)}}/>
          ))}
        </div>
    </div>
  );
}

function ArchivePeopleThumb(props) {
  const thumbRef = useRef();

  const offsetAmount = 200;
  const [offsetTop, setOffsetTop] = useState();
  const [offsetVal, setOffsetVal] = useState(0);
  const [eleHeight, setEleHeight] = useState();
  const [isInViewport, setIsInViewport] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  const [animateInFocus, setAnimateInFocus] = useState(false);
  const [animationDir, setAnimationDir] = useState({x: -15, y: 15})
  const [parallaxSpeed] = useState(Math.random()*2)


// Find thumb document position and height
  useEffect(() => {
    const offset = thumbRef.current.getBoundingClientRect().top;
    const {offsetHeight} = thumbRef.current;
    setOffsetTop(offset);
    setEleHeight(offsetHeight)
    // setOffsetSpeed(Math.random()*2);
  }, [props.deviceHeight])

 var offsetPosPrevious = useRef();
 var offsetPosCurrent = useRef();
 var requestRef = useRef();
 var innerRef = useRef();
 var isInViewportRef = useRef();
 var offsetSpeed = useRef();



  useEffect(() => {
    offsetPosPrevious.current = 0;
    offsetPosCurrent.current = 0;
    isInViewportRef.current = false;
    offsetSpeed.current = Math.random()*2;
    // imageRef.current = innerRef.current.querySelector('.lazy-image-component img');
    // imageRef.current.style.border = 'solid';
    // requestRef.current = requestAnimationFrame(animateThumb);
    return () => cancelAnimationFrame(requestRef.current);
  }, [])



  return (
    <div className={`ArchivePeopleThumb parallaxcontainer`} ref={thumbRef} data-parallaxamount={props.isMobile ? 50 : 200} data-parallaxspeed={parallaxSpeed} key={`/people/${props.data.post_name}`}>
      <div className="inner" ref={innerRef} style={{transform: `rotateX(${animateInFocus ? animationDir.x : 0}deg) rotateY(${animateInFocus ? animationDir.y : 0}deg)`}}>
          <Link to={`/people/${props.data.post_name}`}>
          <div className="thumb-animate" onMouseEnter={() => setInFocus(true)} onMouseLeave={() => setInFocus(false)}>
          {props.data.image ? 
            <ArchivePeopleThumbImage images={props.data.image} link={`/people/${props.data.post_name}`} inFocus={inFocus} parallaxAmount={props.isMobile ? 10 : 50}/>
          : null}
          {/*props.data.image ? 
            <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.url} link={`/people/${props.data.post_name}`} linktype={'internal'}/> : null*/}
            <h5 className="name">{props.data.post_title}</h5>
            <div className="title">{props.data.title}</div>
          </div>
          </Link>
      </div>
    </div>
  );
}

export default connect(state => ({
  mousePos: state.App.mousePos,
  scrollPos: state.App.scrollPosition,
  deviceHeight: state.Device.size.height,
  isMobile: state.Device.isMobile
}))(ArchivePeopleThumb);

import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'

import SEO from './../SEO'
import Footer from './../Footer'

import SingleCasesHeader from './../PageModules/SingleCasesHeader'
import SingleCasesDatabox from './../PageModules/SingleCasesDatabox'
import SingleCasesArticle from './../PageModules/SingleCasesArticle'
import SingleCasesHowWeHelped from './../PageModules/SingleCasesHowWeHelped'
import SingleCasesQuote from './../PageModules/SingleCasesQuote'
import SingleNavigation from './../PageModules/SingleNavigation'

function SingleCases(props) {

  useEffect(() => {
    // if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    // else if (props.routerHash !== '') window.scrollTo(0,837) ;
    // else window.scrollTo(0,0);
  }, [props.dataKey])

  return (
  	<div className={`page page-singlecases${props.mainmenuIsOpen ? ' open' : ''}`}>
      <SEO dataKey={props.dataKey} title={props.apiData.post_title} description={props.apiData.meta_description}/>
      	
      <SingleCasesHeader title={props.apiData.post_title} description={props.apiData.header_description}/>
      <SingleCasesDatabox title={props.apiData.post_title} data={props.apiData.case_data} color={props.apiData.databox_backgroundcolor} logo={props.apiData.logo}/>
      <SingleCasesArticle image={props.apiData.image} article={props.apiData.article}/>
      <SingleCasesQuote data={props.apiData.quote}/>
      <SingleCasesHowWeHelped data={props.apiData.howwehelped || []} title={props.apiData.post_title}/>
      <SingleNavigation pages={props.apiData.next_prev}/>
      
      <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
  return ({
	apiData: state.App.pageData[ownProps.dataKey].data,
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
  routerAction: state.router.action,
  routerHash: state.router.location.hash,
  meta: state.Globals.meta
})
})(SingleCases);

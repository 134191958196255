import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import {Link} from 'react-router-dom'
import './index.scss';

function EmbeddedVideoComponent({iframe, title, widthRatio, active, scale, link, linktype, className, imgClassName}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const widthRatioToUse = widthRatio ? widthRatio : 100
	const scaleToUse = scale ? scale : 1;
	var parse = require('html-react-parser');

  return (
		<div className={`EmbedVideoGridWrapperInner`}>
	  	<div className={`${className ? className : ''} embeddded-video-component`}>
				{parse(iframe)}
			</div>
			{link ?
			<Link to={`/${link.replace("https://cms.peopleventures.vc/", '').replace('stuff/', 'content/')}`}>
					{title ? parse(`<h3 class="videoTitle">${title}</h3>`)
					: null}
			</Link> :
			title && parse(`<h3 class="videoTitle">${title}</h3>`)
			}
		</div>
  );
}

export default EmbeddedVideoComponent;

import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'
import LazyImageComponent from './../../LazyImageComponent'

function SingleContentMain(props) {
  return (
    <div className={`PageModule SingleContentMain`}>
      {props.data?.map((ele, i) => (
        <div className="section" key={i}>
          <div className="col col4">
            <div className="inner"> 
              {ele.left_column}
            </div>
          </div>
          <div className="col col4">
          	<div className="inner" dangerouslySetInnerHTML={{__html: ele.right_column}}/>
      	  </div>
        </div>
      ))}
    </div>
  );
}

export default connect()(SingleContentMain);

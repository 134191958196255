import React from 'react'

import parse, {domToReact} from 'html-react-parser'

export function getApiBase() {
  return process.env.NODE_ENV === 'production' ? 'https://cms.peopleventures.vc' : 
  'https://cms.peopleventures.vc';
  // 'https://cms.2point0.inqvation.dk';
	// 'http://dev.peoplevc-backend';
}

export const convertHTMLStringToReact = (htmlString) => {
    const options = {
      replace: ({attribs, children}) => {
        if (!attribs) return;

        if (attribs.href) {
          return <div>{domToReact(children, options)}</div>
        }
      }
    }

   return parse(htmlString, options)
}

export const wrapString = (str) => {
    var titleArray = str.split('');
    var outputString = '';
    for (let i = 0; i < titleArray.length; i++) {
          var className = titleArray[i] == ' ' ? 'whitespace' : 'letter';
          outputString += '<div class="reveal-char '+className+'">';
          outputString += titleArray[i];
          outputString += '</div>';
        }
    return convertHTMLStringToReact(outputString)
  }

export const MathUtils = {
        // map number x from range [a, b] to [c, d]
          map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
        // linear interpolation
          lerp: (a, b, n) => (1 - n) * a + n * b,
        // distance
          distance: (x1, y1, x2, y2) => {var a = x1 - x2; var b = y1 - y2; return Math.hypot(a, b)}
    };

export const distance = (x1,y1,x2,y2) => {
    var a = x1 - x2;
    var b = y1 - y2;

    return Math.hypot(a,b);
}

export const makePagelinkUrl = (post) => {
  let parent = post.post_type == 'page' ? '' : post.post_type == 'cases' ? 'companies/' : post.post_type == 'stuff' ? 'content/' : post.post_type + '/' 

  return '/' + parent + post.post_name;
}
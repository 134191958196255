import React, {useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'
import LazyImageComponent from './../../LazyImageComponent'
import EmbeddedVideoComponent from './../../EmbeddedVideoComponent'

function SingleContentHeader(props) {
  console.log(props);
  let mediaComponent;
  if (props.contentType?.toLowerCase() === 'video' && props.heroVideo) {
    mediaComponent = <EmbeddedVideoComponent iframe={props.heroVideo}/>
  } else {
    mediaComponent = <LazyImageComponent height={props.heroImage.sizes['fullscreen-height']} width={props.heroImage.sizes['fullscreen-width']} url={props.heroImage.sizes['fullscreen']}/>
  }
  return (
    <div className={`PageModule SingleContentHeader`}>
        <div className="col col8">
        	<div className="inner">
            <div className="section top">
              <h1 className="title">{props.title}</h1>
            	{props.tags ? <ul className="tags">
                {props.tags.map((tag, tagI) => (
                  <li className="tag">{tag.name}</li>
                ))}
              </ul> : null}
            </div>
            <div className="section hero">
              {mediaComponent}
            </div>
          </div>
    	  </div>
    </div>
  );
}

export default connect()(SingleContentHeader);
